.ShareDrawer .ShareDrawer__title {
  padding: 24px 24px 8px 24px;
  font-family: monospace;
  text-transform: uppercase;
  font-size: 20px;
}

.ShareDrawer .ShareDrawer__listItem {
  padding: 0;
}

.ShareDrawer .ShareDrawer__copyIcon {
  color: var(--ui);
}
