.Login {
  display: flex;
  flex-direction: column;
  margin: 16px auto 0;
  text-align: center;
  width: 100%;
  color: var(--main-text);
}

.Login__title {
  text-transform: uppercase;
  font-family: var(--font-fam-knewave);
}

.Login__methods {
  text-align: left;
}

.Login .Login__methodTitle {
  font-family: monospace;
  text-transform: uppercase;
}
