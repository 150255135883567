.CommentsSection {
  color: var(--secondary-text);
}

.CommentsSection__header {
  font-family: monospace;
  font-size: 16px;
}

.CommentsSection__commentContainer {
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--card-bg);
  padding: 16px 22px;
  margin-top: 16px;
}

.CommentsSection___commentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CommentsSection__commentHeaderLeft {
  display: flex;
  align-items: center;
}

.CommentsSection__timeAgo {
  font-size: 12px;
  margin: 0 0 0 8px;
}

.CommentsSection__userProfile {
  margin-top: 16px;
}

.CommentsSection__commentText {
  font-size: 16px;
  font-family: var(--font-fam-inter);
  padding: 8px 0 0 16px;
}

.CommentsSection__inputContainer {
  display: flex;
  background-color: var(--comment-input-bg);
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.CommentsSection__inputContainerInner {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: var(--container-max-width);
  padding: 12px 16px;
}

.CommentsSection__input {
  width: 100%;
  background-color: var(--cardbutton-bg);
  color: var(--main-text);
  border: none;
  font-size: 16px;
  font-family: var(--font-fam-inter);
  padding: 4px 8px;
}

.CommentsSection__button {
  margin-left: 16px;
  padding: 8px;
  background-color: var(--comment-button-bg);
  font-size: 16px;
  font-family: monospace;
  text-transform: uppercase;
}

.CommentsSection__button.m-disabled {
  border: none;
  cursor: not-allowed;
  background-color: var(--cardbutton-bg);
  opacity: 0.6;
  color: var(--secondary-text);
}
