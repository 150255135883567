.Custom404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--main-text);
}

.Custom404__button {
  margin-top: 16px;
}
