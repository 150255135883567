.CustomButton {
  display: flex;
  align-items: center;
  width: max-content;
  height: 50px;
  margin: auto 0;
  background-color: var(--button-bg);
  color: var(--button-text);
  text-transform: uppercase;
  padding: 0 16px;
}

.CustomButton:hover {
  background-color: var(--secondary-text);
}

.CustomButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.CustomButton .CustomButton__label {
  font-family: monospace;
  font-size: 16px;
  text-transform: uppercase;
}

.CustomButton__icon {
  margin-right: 8px;
}
