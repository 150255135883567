.ClapButton {
  font-size: 16px;
  padding: 12px 16px;
  background: none;
  color: var(--main-text);
  border: 1px solid var(--secondary-text);
  border-radius: 8px;
  margin: 0;
}

.ClapButton.m-shrink {
  background-color: var(--secondary-text);
  padding: 8px 12px;
  margin: 4px 4px;
}

.ClapButton:hover {
  padding: 8px 12px;
  margin: 4px 4px;
}

.ClapButton.m-disabled {
  border: none;
  cursor: not-allowed;
  background-color: var(--cardbutton-bg);
}
