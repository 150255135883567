.FeedContainer__userProfile {
  margin-top: 16px;
}

.FeedContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.FeedContainer__feedHeader {
  display: flex;
  justify-content: space-between;
  color: var(--main-text);
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--app-bg);
  padding: 16px 16px;
  margin: 0 -16px;
}

.FeedContainer__feedHeaderToggle {
  display: flex;
  align-items: center;
  margin-left: -12px;
}

.FeedContainer__feedHeaderRight {
  display: flex;
  align-items: center;
}

.FeedContainer .FeedContainer__orderBy {
  font-family: monospace;
  color: var(--main-text);
}

.FeedContainer__footerMessage,
.FeedContainer__emptyFeed {
  font-size: 20px;
  font-family: var(--font-fam-nanum);
  color: var(--secondary-text);
}

.FeedContainer__footerMessage {
  margin: 22px auto;
}

.FeedContainer__emptyFeed {
  margin: auto;
}
