.Navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #181818;
  font-weight: bold;
  z-index: 2;
}

.Navbar__container {
  padding: 0 16px;
  width: 100%;
  height: var(--navbar-height);
  max-width: var(--container-max-width);
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.Navbar__link {
  height: max-content;
  margin: auto 0;
}

.Navbar__logo {
  width: var(--navbar-height);
  object-fit: cover;
  /* TODO Magic number */
  width: 84px;
}

.Navbar__logo:hover {
  opacity: 0.8;
}

.Navbar__right {
  display: flex;
  align-items: center;
}
