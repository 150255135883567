@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Knewave&family=Nanum+Pen+Script&display=swap");

/* Default overrides */

:root {
  --font-fam-knewave: "Knewave", "Verdana", sans-serif;
  --font-fam-inter: "Inter", "Verdana", sans-serif;
  --font-fam-nanum: "Nanum Pen Script", "Verdana", sans-serif;
  --navbar-height: 100px;
  --comment-box-height: 72px;
  --container-max-width: 800px;
}

/* Defaults */
/* TODO Cleanup overrides */
* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-fam-inter);
  background-color: var(--secondary-text);
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  border: none;
  text-decoration: none;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

/* Globals */

.Globals__bandName {
  font-family: var(--font-fam-knewave);
  text-transform: uppercase;
}

/* App styles */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--app-bg);
}
