.FloatingButton {
  display: flex;
  justify-content: flex-end;
}

.FloatingButton .FloatingButton__icon {
  position: fixed;
  bottom: 16px;
  z-index: 1;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 24px;
  background-color: var(--button-bg);
  color: var(--button-text);
}

/* Override MUI's transparency on hover */
.FloatingButton .FloatingButton__icon:hover {
  background-color: var(--button-bg-hover);
}
