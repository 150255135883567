.CardButton {
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: var(--button-bg);
  color: #eef0f1;
  font-family: monospace;
}

.CardButton:hover {
  background: var(--secondary-text);
  color: var(--main-text);
  padding: 8px 12px;
  margin: 4px 4px;
}

.CardButton.m-delete {
  background: var(--warning-bg);
  /* Fixed color regardless of theme */
  color: #eef0f1;
  border: none;
}

.CardButton.m-delete:hover {
  background: var(--warning-bg-hover);
}

.CardButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.CardButton__text {
  margin-left: 8px;
  color: var(--button-text);
}

.CardButton.m-clap,
.CardButton.m-comment {
  font-size: 16px;
  padding: 12px 16px;
  background: none;
  color: var(--main-text);
  border: 1px solid var(--secondary-text);
  border-radius: 8px;
  margin: 0 0 0 16px;
}

.CardButton.m-clap:hover,
.CardButton.m-comment:hover {
  padding: 8px 12px;
  margin: 4px 4px 4px 20px;
}

.CardButton.m-clap.m-disabled {
  border: none;
  cursor: not-allowed;
  background-color: var(--cardbutton-bg);
}

.CardButton.m-shrink {
  background-color: var(--secondary-text);
  padding: 8px 12px;
  margin: 4px 4px 4px 20px;
}
