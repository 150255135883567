.Card {
  display: flex;
  padding: 16px 22px;
  /* Applying margin bottom vs top to avoid slight jitter on card flip */
  margin: 0 0 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--card-bg);
  width: 100%;
  text-align: left;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  /* TODO Magic number */
  min-height: 191px;
}

.Card__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Card__header.m-flexEnd {
  justify-content: flex-end;
}

.Card__title {
  font-family: var(--font-fam-knewave);
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 1px;
  font-size: 20px;
  color: var(--main-text);
  word-wrap: anywhere;
}

.Card .Card__menuIcon {
  color: var(--secondary-text);
}

.Card__genre {
  margin: 0;
  color: var(--secondary-text);
  font-family: var(--font-fam-inter);
  text-transform: lowercase;
  font-size: 20px;
  font-weight: 100;
  word-wrap: anywhere;
}

.Card__country {
  margin: 12px 0 0;
  font-size: 24px;
}

.Card__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
}

.Card__postedInfo {
  margin: 16px 0 0;
  font-size: 14px;
  color: var(--secondary-text);
}

.Card__postedDate {
  border-bottom: 1px solid var(--secondary-text);
}

.Card__footer.m-flexEnd {
  justify-content: flex-end;
}

.Card__buttonsContainer {
  display: flex;
}

.Card__username {
  font-family: var(--font-fam-nanum);
  font-size: 20px;
  vertical-align: middle;
}

.Card__description {
  font-family: monospace;
  font-size: 16px;
  text-align: center;
  color: var(--secondary-text);
  width: 100%;
}
