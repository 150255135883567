.NavbarMenu {
  margin: auto 0;
  padding-left: 8px;
  margin-right: -8px;
}

.NavbarMenu .NavbarMenu__icon {
  color: #ccc;
}

.NavbarMenu__menuLink {
  display: flex;
}
