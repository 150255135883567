.CountrySelector {
  font-family: monospace;
  font-size: 16px;
  color: var(--secondary-text);
  background: none;
  border: none;
  margin: 12px 0 0;
  width: fit-content;
  outline: none;
}
