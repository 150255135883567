.PasswordResetForm__container {
  display: flex;
  flex-direction: column;
  margin: 16px auto 0;
  text-align: center;
  width: 100%;
  color: var(--main-text);
}

.PasswordResetForm {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  text-align: left;
}

.PasswordResetForm__title {
  text-transform: uppercase;
  font-family: var(--font-fam-knewave);
}

.PasswordResetForm__infoContainer {
  display: flex;
  margin-top: 16px;
}

.PasswordResetForm__infoText {
  padding-left: 8px;
}

.PasswordResetForm .PasswordResetForm__inputContainer {
  margin-top: 16px;
}

.PasswordResetForm .PasswordResetForm__inputLabel {
  color: var(--secondary-text);
}

.PasswordResetForm .PasswordResetForm__input {
  color: var(--main-text);
}

.PasswordResetForm__submitButton {
  margin-top: 16px;
}
