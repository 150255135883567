.Splash {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #181818;
  position: fixed;
  z-index: 2;
  /* Need to use both opacity and visibility to "unmount" splash screen after transition */
  opacity: 1;
  visibility: visible;
}

.Splash.m-hide {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.Splash__logo {
  width: 50vw;
  margin: auto;
  max-width: 250px;
}
