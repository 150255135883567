/* Wrapping in a flex container seems to allow for initial length to be reduced */
.AutogrowingInput__container {
  display: flex;
}

/* Hack to autogrow textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */

.AutogrowingInput,
.AutogrowingInput__textarea {
  display: grid;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}
.AutogrowingInput::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.AutogrowingInput .AutogrowingInput__textarea {
  resize: none;
  overflow: hidden;
}
.AutogrowingInput .AutogrowingInput__textarea,
.AutogrowingInput::after {
  word-wrap: anywhere;
  grid-area: 1 / 1 / 2 / 2;
}
